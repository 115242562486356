<template>
  <div class="reidentificationSheet">
    <el-dialog
      title="重新识别"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="448px"
    >
      <div class="model-title">
        <template v-if="indexType == 1">请选择需要重新识别的批次</template>
        <template v-if="indexType == 2">请选择需要重新识别的纸张状态</template>
      </div>
      <el-input
        v-model="keyword"
        :placeholder="indexType == 1 ? '请输入批次名称' : '请输入纸张状态'"
        @keyup.enter.native="handleClick"
      >
        <i
          slot="suffix"
          class="el-icon-search el-input__icon"
          @click="handleClick"
        >
        </i>
      </el-input>
      <div class="check-list-box">
        <div class="title">
          <div>
            <span class="span-btn" @click="checkChange(1)">全选</span>
            <span class="span-btn" @click="checkChange(2)">反选</span>
          </div>
          <div class="text-box">
            已选
            <span>{{ checkList.length }} </span>
            <template v-if="indexType == 1">个批次</template>
            <template v-if="indexType == 2">个状态</template>
          </div>
        </div>
        <div class="check-cnt edit-scroll-style">
          <div v-if="noData" style="margin-top: 18px">暂无数据</div>
          <el-checkbox-group v-else v-model="checkList">
            <template v-for="(v, i) in dataList">
              <el-checkbox v-if="getShow(v)" :key="i" :label="v.value">
                {{ v.label }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitRole()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { identifyagainBatch } from "@/core/api/exam/examScanSheet";
export default {
  name: "ReidentificationSheet",

  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      noData: false,
      keyword: "",
      copykeyword: "",
      dataList: [],
      checkList: [],
      indexType: 1,
    };
  },
  created() {},
  //   mounted() {
  //     this.dialogVisible = true;
  //   },
  methods: {
    showPassword() {
      if (this.checkList.length == 0) {
        this.$message({
          showClose: true,
          message: "请先选择学校",
          type: "warning",
        });
        return;
      }
    },
    checkChange(type) {
      if (type == 1) {
        let arr = this.dataList.map((item) => item.value);
        this.checkList = this.checkList.concat(arr);
        this.checkList = [...new Set(this.checkList)];
      }
      if (type == 2) {
        let arr = this.dataList.map((item) => item.value);
        // 过滤出原本应该有的 但是又不在当前显示列表的
        let array = this.checkList.filter((item) => !arr.includes(item));
        arr = arr.filter((item) => !this.checkList.includes(item));
        this.checkList = array.concat(arr);
      }
    },
    submitRole() {
      let tipsArr = [];
      const h = this.$createElement;
      if (this.indexType == 0) {
        if (this.$parent.multipleSelection.length == 0) {
          this.$message({
            message: "请选择需要重新识别的试卷",
            type: "warning",
            showClose: true,
          });
          return;
        }
        tipsArr = [
          h("span", null, "此操作将重新识别共 "),
          h(
            "span",
            { style: "color: #2474ED" },
            this.$parent.multipleSelection.length
          ),
          h("span", null, " 张试卷，是否继续？"),
        ];
      }
      if (this.indexType == 1) {
        if (this.checkList.length == 0) {
          this.$message({
            message: "请选择需要重新识别的批次",
            type: "warning",
            showClose: true,
          });
          return;
        }
        let subArr = this.checkList.slice(0, 5);
        tipsArr = [
          h("span", null, "此操作将重新识别 "),
          h("span", { style: "color: #2474ED" }, subArr.join("、")),
          h("span", null, " 等 "),
          h("span", { style: "color: #2474ED" }, this.checkList.length),
          h("span", null, " 个批次试卷, 是否继续?"),
        ];
      }
      if (this.indexType == 2) {
        if (this.checkList.length == 0) {
          this.$message({
            message: "请选择需要重新识别的纸张状态",
            type: "warning",
            showClose: true,
          });
          return;
        }
        let arr = [];
        this.dataList.map((item) => {
          if (this.checkList.indexOf(item.value) != -1) {
            arr.push(item.label);
          }
        });
        // let subArr = arr.slice(0, 5);
        tipsArr = [
          h("span", null, "此操作将重新识别 "),
          h("span", { style: "color: #2474ED" }, arr.join("、")),
          h("span", null, " 等 "),
          h("span", { style: "color: #2474ED" }, this.checkList.length),
          h("span", null, " 个纸张状态的试卷, 是否继续?"),
        ];
      }
      if (this.indexType == 3) {
        tipsArr = [
          h("span", null, "此操作将重新识别扫描的 "),
          h("span", { style: "color: #2474ED" }, "全部试卷"),
          h("span", null, " , 是否继续? "),
        ];
      }
      this.dialogVisible = false;
      this.$msgbox({
        title: "提示",
        message: h("p", null, tipsArr),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.submitScan();
        })
        .catch(() => {
          if (this.indexType == 1 || this.indexType == 2) {
            this.dialogVisible = true;
          }
        });
    },
    submitScan() {
      let data = {
        type: this.indexType,
        examPaperId: this.$route.query.examPaperId,
      };

      if (this.indexType == 0) {
        let array = this.$parent.multipleSelection.map(
          (item) => item.scanRecordId
        );
        data.scanRecordIdList = array;
      }
      if (this.indexType == 1) {
        data.deviceAndBatchList = this.checkList;
      }
      if (this.indexType == 2) {
        data.recognitionStatusList = this.checkList;
      }

      this.identifyagainBatch(data);
    },
    identifyagainBatch(data) {
      this.btnLoading = true;
      identifyagainBatch(data)
        .then(() => {
          this.$message({
            type: "success",
            message: "重新识别成功!",
            showClose: true,
          });
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$parent.scanrecordbatch();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    handleClick() {
      this.copykeyword = this.keyword;
      this.showList();
    },
    showList() {
      let arr = this.dataList.filter(
        (item) =>
          !this.copykeyword ||
          (this.copykeyword && item.label.indexOf(this.copykeyword) != -1)
      );
      if (arr.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
    getShow(v) {
      if (!this.copykeyword) return true;
      if (v.label.indexOf(this.copykeyword) == -1) {
        return false;
      }
      return true;
    },
    init(type) {
      this.dataList = [];
      this.checkList = [];
      this.indexType = type;
      if (type == 0 || type == 3) {
        this.submitRole();
        return;
      }

      if (type == 1) {
        this.$parent.scanMessageList.map((item) => {
          if (item.batch != "全部" && item.batch != "整体") {
            this.dataList.push({
              label: item.deviceId + "-" + item.batch,
              value: item.deviceId + "-" + item.batch,
            });
          }
        });
        if (this.dataList.length == 0) {
          this.$message({
            message: "当前考试不存在批次!",
            type: "warning",
            showClose: true,
          });
          return;
        }
      }
      if (type == 2) {
        this.dataList = this.$parent.errTypeList;
      }
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.reidentificationSheet {
  .check-list-box {
    width: 100%;
    border: 1px solid #dee0e7;
    margin-top: 18px;
    .check-cnt {
      padding: 18px;
      padding-top: 0;
      max-height: 322px;
      .list-box {
        .check-list {
          display: flex;
          justify-content: space-between;
          margin-top: 18px;
          i {
            cursor: pointer;
          }
        }
      }
      .el-checkbox {
        display: block;
        margin-top: 18px;
        margin-bottom: 0;
      }
    }
    .title {
      padding: 10px 18px;
      background: #f5f7fa;
      border-bottom: 1px solid #dee0e7;
      display: flex;
      justify-content: space-between;
      .text-box {
        span {
          color: #2474ed;
        }
      }

      .span-btn {
        margin-right: 18px;
        cursor: pointer;
      }
    }
  }
  .el-input {
    width: 214px;
  }
  .school-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tips {
    padding: 10px 18px;
    background: #fff3f3;
    color: #292626;
    margin-bottom: 18px;
  }
  .model-title {
    color: #2474ed;
    margin-bottom: 18px;
  }
}
</style>
