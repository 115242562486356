<template>
  <div class="showErrImg">
    <el-dialog
      title="扫描异常，请重新扫描当前图片之后的试卷！"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <img :src="imgUrl" style="width: 100%" alt="" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ShowErrImg",
  components: {},
  data() {
    return {
      dialogVisible: false,
      imgUrl: "",
    };
  },
  created() {},
  methods: {
    init(url) {
      this.imgUrl = url;
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.showErrImg {
}
</style>
