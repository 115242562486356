<template>
  <div class="scanningParameters">
    <el-dialog title="设置扫描参数" :visible.sync="dialogVisible" width="576px">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
      >
        <el-form-item label="纸张尺寸：">
          <el-input
            v-model="ruleForm.size"
            disabled
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="试卷样式：">
          <el-input
            v-model="ruleForm.style"
            disabled
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="indexPaper.sheetMarkType == 1" label="颜色模式：">
          <el-select
            v-model="colorType"
            :disabled="indexPaper.sheetMarkType == 1"
            placeholder="请选择颜色模式"
          >
            <el-option
              v-for="(item, index) in colorTypeOpt"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="indexPaper.sheetMarkType != 1" label="颜色模式：">
          <el-select v-model="ruleForm.colorType" placeholder="请选择颜色模式">
            <el-option
              v-for="(item, index) in colorTypeOpt"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分 辨 率：">
          <el-select v-model="ruleForm.resolution" placeholder="请选择分辨率">
            <el-option
              v-for="(item, index) in resolutionOpt"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="旋转角度：">
          <el-select v-model="ruleForm.angle" placeholder="请选择旋转角度">
            <el-option
              v-for="(item, index) in rateDegOpt"
              :key="index"
              :label="item.label + '度'"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="色彩过滤：">
          <el-select v-model="ruleForm.colorFilter">
            <el-option
              v-for="(item, index) in colorFilter"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="对 比 度：" prop="contrast">
          <el-input
            v-model="ruleForm.contrast"
            placeholder="-1000～1000之间的值"
            oninput="value=value.replace(/[^0-9-]/g,'')"
            @blur="ruleForm.contrast = $event.target.value"
          ></el-input>
        </el-form-item>
        <el-form-item label="亮度：" prop="bright">
          <el-input
            v-model="ruleForm.bright"
            placeholder="-1000～1000之间的值"
            oninput="value=value.replace(/[^0-9-]/g,'')"
            @blur="ruleForm.bright = $event.target.value"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="背面亮度：" prop="backBright">
          <el-input
            v-model="ruleForm.backBright"
            placeholder="-127～255之间的数值"
            oninput="value=value.replace(/[^\d]/g,'')"
            @blur="ruleForm.backBright = $event.target.value"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="色彩增强：">
          <el-select v-model="ruleForm.colorEnhance">
            <el-option
              v-for="(item, index) in whether"
              :key="index"
              :label="item.labelName"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="纸张自适应：">
          <el-radio-group v-model="ruleForm.paperAdaptive">
            <el-radio
              v-for="(item, index) in whether"
              :key="index"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="图像纠偏：">
          <el-radio-group v-model="ruleForm.imageCorrect">
            <el-radio
              v-for="(item, index) in whether"
              :key="index"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="祛除黑框：">
          <el-radio-group v-model="ruleForm.removeBlack">
            <el-radio
              v-for="(item, index) in whether"
              :key="index"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="自动优化图像质量：">
          <el-radio-group v-model="ruleForm.autoPptimize">
            <el-radio
              v-for="(item, index) in whether"
              :key="index"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reset()">重 置</el-button>
        <el-button type="primary" @click="submitForm()"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getStore, setStore } from "@/core/util/store";
import {
  colorTypeOpt,
  resolutionOpt,
  rateDegOpt,
  whether,
  colorFilter,
} from "@/core/util/scanJson";
export default {
  name: "ScanningParameters",
  data() {
    const checkNumber = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      if (isNaN(Number(value))) {
        return callback(new Error("-1000～1000之间的数值"));
      }
      setTimeout(() => {
        if (Number(value) > 1000 || Number(value) < -1000) {
          return callback(new Error("-1000～1000之间的数值"));
        }
        callback();
      }, 300);
    };
    return {
      dialogVisible: false,
      ruleForm: {
        size: "",
        style: "",

        //  imageCorrect removeBlack autoPptimize
      },
      rules: {
        contrast: [{ validator: checkNumber, trigger: "blur" }],
        frontBright: [{ validator: checkNumber, trigger: "blur" }],
        bright: [{ validator: checkNumber, trigger: "blur" }],
      },
      rateDegOpt: rateDegOpt,
      colorTypeOpt: colorTypeOpt,
      resolutionOpt: resolutionOpt,
      whether: whether,
      colorFilter: colorFilter,
      examScanParameter: {},
      indexPaper: {},
      colorType: 2,
    };
  },
  created() {},
  methods: {
    submitForm() {
      this.btnLoading = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //   alert("submit!");
          this.commit();
        } else {
          this.btnLoading = false;
          //   console.log("error submit!!");
          return false;
        }
      });
    },
    commit() {
      let scanParameter = getStore({ name: "scan-parameter" });
      //   从缓存中去取一次
      let addType = true;
      if (scanParameter) {
        scanParameter.map((item) => {
          if (item.scannerModel == this.clientData.scannerModel) {
            addType = false;
            Object.assign(item.form, this.ruleForm);
          }
        });
      } else {
        scanParameter = [];
      }
      if (addType) {
        scanParameter.push({
          scannerModel: this.clientData.scannerModel || 1,

          form: this.ruleForm,
        });
      }
      this.$message({
        showClose: true,
        message: "参数设置成功,请继续扫描!",
        type: "success",
      });
      this.dialogVisible = false;
      setStore({ name: "scan-parameter", content: scanParameter });
    },
    init(clientData, data) {
      this.examScanParameter = this.$parent.examScanParameter;
      if (!clientData.scannerModel) {
        clientData.scannerModel = 1;
      }
      this.clientData = clientData;

      let scanParameter = getStore({ name: "scan-parameter" });

      Object.assign(this.ruleForm, data);
      this.indexPaper = this.$parent.indexPaper;
      //   从缓存中去取一次
      let addType = true;
      if (scanParameter) {
        scanParameter.map((item) => {
          if (item.scannerModel == clientData.scannerModel) {
            addType = false;
            Object.assign(this.ruleForm, item.form);
          }
        });
      }
      if (addType) {
        Object.assign(this.ruleForm, this.examScanParameter);
      }

      this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
      this.dialogVisible = true;
    },
    reset() {
      Object.assign(this.ruleForm, this.examScanParameter);
      this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
    },
  },
};
</script>
<style lang="scss" scoped>
.scanningParameters {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .el-form-item {
      width: 48%;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      .el-input,
      .el-select {
        width: 174px;
      }
      .el-radio {
        margin-bottom: 0;
      }
      ::v-deep {
        .el-form-item__content,
        .el-form-item__label {
          line-height: 1;
          flex-shrink: 0;
          min-width: 88px;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
