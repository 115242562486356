<template>
  <div class="scanRecognition">
    <el-dialog
      title="参数设置"
      :visible.sync="dialogVisible"
      width="720px"
      :close-on-click-modal="false"
    >
      <el-form
        ref="submitFrom"
        :model="submitFrom"
        label-width="165px"
        :rules="rules"
      >
        <el-form-item
          label="最小灰度值："
          prop="minObjectiveGray"
          class="is-required"
        >
          <el-input
            v-model="submitFrom.minObjectiveGray"
            :max="255"
            :min="0"
            oninput="value=value.replace(/[^\d]/g,'')"
            @blur="submitFrom.minObjectiveGray = $event.target.value"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="最大灰度值："
          prop="maxObjectiveGray"
          class="is-required"
        >
          <el-input
            v-model="submitFrom.maxObjectiveGray"
            :max="255"
            :min="0"
            oninput="value=value.replace(/[^\d]/g,'')"
            @blur="submitFrom.maxObjectiveGray = $event.target.value"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="定位点识别比率："
          prop="locationCorrectRate"
          class="is-required"
        >
          <el-input v-model="submitFrom.locationCorrectRate"></el-input>
        </el-form-item>
        <el-form-item
          label="缺考标记识别比率："
          prop="absentCorrectRate"
          class="is-required"
        >
          <el-input v-model="submitFrom.absentCorrectRate"></el-input>
        </el-form-item>
        <el-form-item
          label="准考证号识别比率："
          prop="examNoCorrectRate"
          class="is-required"
        >
          <el-input v-model="submitFrom.examNoCorrectRate"></el-input>
        </el-form-item>
        <el-form-item
          label="客观题识别比率："
          prop="objectiveCorrectRate"
          class="is-required"
        >
          <el-input v-model="submitFrom.objectiveCorrectRate"></el-input>
        </el-form-item>
        <el-form-item
          label="选做题识别比率："
          prop="optionQuesCorrectRate"
          class="is-required"
        >
          <el-input v-model="submitFrom.optionQuesCorrectRate"></el-input>
        </el-form-item>
        <el-form-item label="条形码灰度值：" prop="barcodeGray">
          <el-input
            v-model="submitFrom.barcodeGray"
            oninput="value=value.replace(/[^\d]/g,'')"
            @blur="submitFrom.barcodeGray = $event.target.value"
          ></el-input>
        </el-form-item>
        <el-form-item label="红色色相最低值：" prop="minRedHue2">
          <el-input
            v-model="submitFrom.minRedHue2"
            oninput="value=value.replace(/[^\d]/g,'')"
            placeholder="建议145-156"
            @blur="submitFrom.minRedHue2 = $event.target.value"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { parameterSave, parameterGet } from "@/core/api/exam/examScanSheet";
export default {
  name: "ScanRecognition",
  components: {},
  data() {
    const barcodeGray = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入条形码灰度值"));
      }
      value = Number(value);
      if (!value && value !== 0) {
        callback(new Error("请输入数字值"));
      } else {
        if (value > 255 || value < 0) {
          callback(new Error("区间为0-255之间整数"));
        } else {
          callback();
        }
      }
    };
    const maxGray = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入"));
      }
      value = Number(value);
      if (!value && value !== 0) {
        callback(new Error("请输入数字值"));
      } else {
        if (value > 255 || value < 1) {
          callback(new Error("区间为1-255之间整数"));
        } else {
          callback();
        }
      }
    };
    const rulesType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入"));
      }
      value = Number(value);
      //   console.log(value);
      if (!value && value !== 0) {
        callback(new Error("请输入数字"));
      } else {
        if (value >= 0 && value <= 1) {
          callback();
        } else {
          return callback(new Error("请输入0-1之间的值"));
        }
      }
    };
    return {
      dialogVisible: false,
      btnLoading: false,
      active: 1,
      basequestionList: [],
      groupList: [],
      recognition: 0,
      submitFrom: {
        // 最小灰度值：
        minObjectiveGray: 160,
        // 最大灰度值：
        maxObjectiveGray: 200,
        // 定位点识别比率：
        locationCorrectRate: 0.5,
        // 缺考标记识别比率：
        absentCorrectRate: 0.7,
        // 准考证号识别比率：
        examNoCorrectRate: 0.75,
        // 缺考标记识别比率：
        optionQuesCorrectRate: 0.7,
        // 客观题识别比率：
        objectiveCorrectRate: 0.7,
        // 条形码灰度值
        barcodeGray: 0,
        minRedHue2: 156,
      },
      rules: {
        barcodeGray: [{ validator: barcodeGray, trigger: "blur" }],
        minRedHue2: [{ validator: maxGray, trigger: "blur" }],
        minObjectiveGray: [{ validator: maxGray, trigger: "blur" }],
        maxObjectiveGray: [{ validator: maxGray, trigger: "blur" }],
        locationCorrectRate: [{ validator: rulesType, trigger: "blur" }],
        absentCorrectRate: [{ validator: rulesType, trigger: "blur" }],
        examNoCorrectRate: [{ validator: rulesType, trigger: "blur" }],
        optionQuesCorrectRate: [{ validator: rulesType, trigger: "blur" }],
        objectiveCorrectRate: [{ validator: rulesType, trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    check(type) {
      if (type == 1) {
        this.groupList = [];
        this.basequestionList.map((item) => {
          this.groupList.push(item.questionGroupId);
        });
      }
      if (type == 2) {
        let array = this.basequestionList.filter(
          (item) => this.groupList.indexOf(item.questionGroupId) == -1
        );
        this.groupList = [];
        array.map((item) => {
          this.groupList.push(item.questionGroupId);
        });
      }
      if (type == 3) {
        this.groupList = [];
      }
    },
    openTips() {
      this.$confirm("正在执行重新识别，请完成识别后查看…", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(() => {})
        .catch(() => {});
    },
    submit() {
      if (
        Number(this.submitFrom.minObjectiveGray) >
        Number(this.submitFrom.maxObjectiveGray)
      ) {
        this.$message({
          message: "最小灰度值不能大于最大灰度值!",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.btnLoading = true;
      this.$refs.submitFrom.validate((valid) => {
        if (valid) {
          this.setSubmit();
          return;
        } else {
          this.btnLoading = false;
          return false;
        }
      });
    },
    setSubmit() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        content: JSON.stringify(this.submitFrom),
        id: this.indexId,
      };
      this.btnLoading = true;
      parameterSave(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "参数设置成功!",
            type: "success",
          });
          this.btnLoading = false;
          this.dialogVisible = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    init() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.submitFrom.resetFields();
        let data = {
          examPaperId: this.$route.query.examPaperId,
        };
        this.indexId = "";
        Object.assign(this.submitFrom, this.$options.data().submitFrom);
        parameterGet(data).then((res) => {
          if (res.data.data) {
            this.indexId = res.data.data.id;
            let json = JSON.parse(res.data.data.content);
            Object.assign(this.submitFrom, json);
          }
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.scanRecognition {
  .edit-scroll-style {
    max-height: 200px;
    // height: 300px;
  }
  ::v-deep .el-form-item__error {
    white-space: nowrap;
    margin-top: -0.5em;
  }
  .edit-scroll-style {
    .el-checkbox {
      width: 186px;
      margin-right: 0;
    }
  }
  .check-box {
    padding-left: 4px;
    margin-top: 12px;
  }
  ::v-deep .el-dialog__body {
    // padding: 20px 0 0 20px;
    .el-form-item__label {
      padding: 0;
    }
  }
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 50%;
      margin-bottom: 8px;
      .el-radio {
        margin-bottom: 0;
      }
      .el-input {
        width: 100%;
      }
    }
  }
}
</style>
