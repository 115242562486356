// 默认系统参数
export const examScanParameter = {
  // 分辨率
  resolution: 200,
  // 颜色
  colorType: 1,
  //   旋转角度
  angle: -90,
  //   对 比 度
  contrast: "",
  //   //   正面亮度：
  //   frontBright: "",
  //   //   背面亮度：
  //   backBright: "",
  //   亮度
  bright: "",
  //   色彩增强：
  //   colorEnhance: 0,
  //   双张检测：
  doubleCheck: 1,
  //   纸张自适应：
  paperAdaptive: 0,
  //   色彩过滤：
  colorFilter: 0,
  //   图像纠偏：
  imageCorrect: 1,
  //   祛除黑框：
  removeBlack: 1,
  //   自动优化图像质量：
  autoPptimize: 1,
};
//
// 旋转角度
export const rateDegOpt = [
  {
    value: 0,
    label: 0,
  },
  {
    value: 90,
    label: 90,
  },
  {
    value: 180,
    label: 180,
  },
  {
    value: -90,
    label: -90,
  },
];
// 分辨率
export const resolutionOpt = [
  {
    value: 100,
    label: 100,
  },
  {
    value: 200,
    label: 200,
  },
  {
    value: 300,
    label: 300,
  },
  {
    value: 400,
    label: 400,
  },
  {
    value: 500,
    label: 500,
  },
  {
    value: 600,
    label: 600,
  },
];
// 答题卡颜色
export const colorTypeOpt = [
  {
    value: 1,
    label: "灰度",
  },
  {
    value: 2,
    label: "256bit 彩色",
  },
];
// 公共参数
export const whether = [
  { value: 1, label: "是", labelName: "有" },
  { value: 0, label: "否", labelName: "无" },
];

// 色彩过滤
export const colorFilter = [
  { value: 0, label: "无" },
  { value: 1, label: "红色过滤" },
  { value: 2, label: "红色增强" },
];
